import React, { createContext, useContext } from "react";
import Cookies from "js-cookie";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {  Navigate, useNavigate } from "react-router-dom";

import LoadingContainer from '../components/LoadingContainer';

import { getUser } from "api/users";
import pages from "routes/main";

const Context = createContext({});

export function useUser() {
    return useContext(Context);
}

const publicPages = pages.reduce((acc, {routes} = {}) => {
    return [
        ...acc,
        ...routes?.filter(({private: isPrivate}) => !isPrivate).map(({path}) => path) || []
    ]
}, []);

export function UserContext({children}) {

    const cache = useQueryClient();
    // const location = useLocation();
    const navigate = useNavigate();
    const userToken = Cookies.get('user-token');
    const userId = Cookies.get('user-id');

    const { data: user, isLoading } = useQuery({
        queryKey: ["user", userId],
        queryFn: () => getUser(),
        enabled: !!userId,
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });

    const logout = () => {
        Cookies.remove('user-token');
        cache.invalidateQueries();
        return navigate('/user/login');
    }

    const isPublic = publicPages.includes(location.pathname);

    if(!userToken && !isPublic) {
        let backUrl = new URLSearchParams({
            backUrl: `${location.pathname}${location.search}`
        });

        return <Navigate replace to={`/user/login?${backUrl}`} />
    }

    return (
        <Context.Provider
            value={{
                isLoggedIn: !!userToken,
                user,
                logout
            }}
        >
            <LoadingContainer isLoading={isLoading}>
                {children}
            </LoadingContainer>
        </Context.Provider>
    )
}